div.entry-container {
  border-radius: 5px;
  /* margin: auto; */
  margin: 0 0 2em 0;
  position: relative;
  display: flex;
  flex-direction: row;
}

body {
  font-family: "JetBrains Mono", monospace;
}

/* .title {
    font-size: 30px;
    font-weight: 800;
    margin-top: 0.5em;
    opacity: 87%;
    color: var(--colour4);
} */

.title-container {
  width: 70%;
  margin: 0.7em auto 0 auto;
  border-radius: 5px;
  text-align: center;
}

.title-container div {
  /* width: 80%; */
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
}

.details {
  /* color: var(--colour4); */
  opacity: 60%;
}

.details > li {
  margin-bottom: 20px;
}

.image {
  width: 100%;
  border-radius: 10px;
}

.entry-card {
  background-color: var(--colour2);
  width: 50%;
  margin: 2em auto;
  border-radius: 5px;
}

.remove-padding {
  padding: 0 !important;
}

@media (min-width: 1200px) {
  .details {
    font-size: 1.4em;
    font-weight: 600;
  }
}

@media (max-width: 1200px) {
  .details {
    font-size: 1.3em;
    font-weight: 600;
  }
}

@media (max-width: 1000px) {
  div.entry-card {
    width: 80% !important;
  }

  .details {
    font-size: 1.3em;
  }
}

@media (max-width: 768px) {
  .details {
    font-size: 1.2em;
  }

  .entry-container {
    flex-direction: column !important;
    align-items: center;
  }

  .text-container {
    width: 90% !important;
  }

  .image-container {
    width: 70% !important;
    padding: 0 !important;
    margin-top: 20px;
  }

  .image {
    margin: 0;
  }

  .home-entry .image {
    margin-right: 10px !important;
  }
}

@media (max-width: 616px) {
  .project-entry .title {
    font-size: 1.5em;
  }
}

@media (max-width: 595px) {
  .title-container {
    width: 90%;
  }
}
